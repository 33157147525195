import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'
import styled from 'styled-components'

const ArticlePage = ({ location, data }) =>
(
  <Layout>

    <SEO title="Celebrating 100 customers with Summon"
      description="This month (June 2021) we have celebrated a major milestone, having signed up 100 customers and their prestige cars."
      location={location}
      image={data.summon100.childImageSharp.fixed.src}
    />

    <PageHeader
      title="Celebrating 100 customers with Summon"
      text="This month (June 2021) we have celebrated a major milestone, having signed up 100 customers and their prestige cars. "
    />

    <SectionPageMargin>

      <IntroBlock>

        <ImageBlock >
          <Img fixed={data.summon100.childImageSharp.fixed} style={{ marginTop: '30px' }} alt="Celebrating 100 customers with Summon" />
        </ImageBlock>

        <div>
          <Heading2>100 prestige cars, 100% dedication </Heading2>

          <Paragraph1>
            We're celebrating a major milestone this month - we have just signed up our 100th customer to sell their prestige car on the owners behalf.
          </Paragraph1>

          <Paragraph1>
            With Summon, our promise is “the best of both worlds” for owners of prestige cars who wish to sell for the best possible price, without all the hassles of selling privately.
          </Paragraph1>

          <Paragraph1>
            Summon is an alternative to selling a prestige car (Audi, BMW, Mercedes-Benz and Porsche are our most popular) to a dealership or wholesaler (where you lose 15% - 20% of the value of the car), or selling the car yourself privately (which is very inconvenient and time consuming).
          </Paragraph1>

          <Paragraph1>
            With this new milestone, that is 100 prestige cars entrusted by the owner with us to sell on their behalf, and the average value of our customer's cars is $100,000.
          </Paragraph1>

          <Paragraph1>
            We are growing quickly as more prestige car owners realise they can now get the best of both worlds. Now onto the next 100!
          </Paragraph1>
        </div>

      </IntroBlock>

    </SectionPageMargin>

    <ReadyToGetStarted />

  </Layout>

)

export default ArticlePage

const IntroBlock = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;

  }  

`;

const ImageBlock = styled.div`
  width: 300px;
  margin-right: 30px;

  @media (max-width: 600px) {
    margin-right: 0px;

  }  

`;


export const query = graphql`
	query  {

    summon100: file(relativePath: { eq: "articles/100-cars-article-big.png" }) {
      childImageSharp {
        fixed(width:300) {
        ...GatsbyImageSharpFixed
        }
      }
    }
	
	}
`

